import api_gtfs from '../api-gtfs'
class GtfsService {

    verifyEmail(email){
        return api_gtfs.get('/validateEmailMetrobus/' + email);
    }

    senderEmail(idCliente, email){
        //let headers = [{'Authorization':token, 'Access-Control-Allow-Origin':'*'}]
        return api_gtfs.post('/senderEmailGtfs/' + idCliente + '/' + email);
    }

    gravarUsuario(user){
        return api_gtfs.post('/registerUserMetrobus', user);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new GtfsService()