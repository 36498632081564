import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import GtfsService from "../services/GtfsService";
import validator from "validator";
import history from "../history";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
class RegisterComponent extends Component {
  showAlertUserSaved = () => {
    Swal.fire({
      icon: "success",
      title: "Usuario registrado",
    });
  };

  showAlertUserRegistered = () => {
    Swal.fire({
      icon: "warning",
      title: "Correo electronico ya registrado",
    });
  };

  showAlertUserNotSaved = () => {
    Swal.fire({
      icon: "error",
      title: "Error al registrar usuario",
      text: "Por favor, póngase en contacto con el administrador",
    });
  };

  showLoadingRegister = () => {
    Swal.fire({
      title: "Espere por favor !",
      html: "Registrando usuario", // add html attribute if you want or remove
      allowOutsideClick: false,
      allowEscapeKey: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  showAlertAcceptTerm = () => {
    Swal.fire({
      icon: "warning",
      title: "Debes aceptar los términos",
      confirmButtonText: "Lectura",
    }).then((result) => {
      if (result.isConfirmed) {
        this.handleShow();
      }
    });
    return;
  };

  showAlertEmailNotValid = () => {
    Swal.fire({
      icon: "warning",
      title: "Formato de correo inválido",
    });
    return;
  };

  showAlertNotFoundDadosInteresse = () => {
    Swal.fire({
      icon: "warning",
      title: "Seleccione al menos una opción del campo 6",
    });
    return;
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      organizacao: "",
      representante: "",
      telefone: "",
      correioEletronico: "",
      descricao: "",
      dadosInteresse1: false,
      dadosInteresse2: false,
      dadosInteresse3: false,
      dadosInteresse4: false,
      dadosInteresse5: false,
      termos: false,
      acessoApi: false,
      ipAceite: "",
    };
    this.changeOrgHandler = this.changeOrgHandler.bind(this);
    this.changeRepHandler = this.changeRepHandler.bind(this);
    this.changeTelHandler = this.changeTelHandler.bind(this);
    this.changeCorEleHandler = this.changeCorEleHandler.bind(this);
    this.changeDescHandler = this.changeDescHandler.bind(this);
    this.changeInterHandler = this.changeInterHandler.bind(this);
    this.changeTermosHandler = this.changeTermosHandler.bind(this);
    this.changeAcessoApiHandler = this.changeAcessoApiHandler.bind(this);

    this.changeDesc1Handler = this.changeDesc1Handler.bind(this);
    this.changeDesc2Handler = this.changeDesc2Handler.bind(this);
    this.changeDesc3Handler = this.changeDesc3Handler.bind(this);
    this.changeDesc4Handler = this.changeDesc4Handler.bind(this);
    this.changeDesc5Handler = this.changeDesc5Handler.bind(this);
  }

  changeOrgHandler = (event) => {
    this.setState({ organizacao: event.target.value });
  };
  changeRepHandler = (event) => {
    this.setState({ representante: event.target.value });
  };
  changeTelHandler = (event) => {
    this.setState({ telefone: event.target.value });
  };
  changeCorEleHandler = (event) => {
    this.setState({ correioEletronico: event.target.value });
  };
  changeDescHandler = (event) => {
    this.setState({ descricao: event.target.value });
  };
  changeInterHandler = (event) => {
    this.setState({ dadosInteresse: event.target.value });
  };
  changeTermosHandler = (event) => {
    this.setState({ termos: event.target.checked });
  };
  changeAcessoApiHandler = (event) => {
    this.setState({ acessoApi: event.target.checked });
  };

  changeDesc1Handler = (event) => {
    this.setState({ dadosInteresse1: event.target.checked });
  };
  changeDesc2Handler = (event) => {
    this.setState({ dadosInteresse2: event.target.checked });
  };
  changeDesc3Handler = (event) => {
    this.setState({ dadosInteresse3: event.target.checked });
  };
  changeDesc4Handler = (event) => {
    this.setState({ dadosInteresse4: event.target.checked });
  };
  changeDesc5Handler = (event) => {
    this.setState({ dadosInteresse5: event.target.checked });
  };

  //Resgata o IP do usuário assim que a tela é carregada
  componentDidMount() {
    axios
      .get("https://api.ipify.org?format=json")
      .then((res) => {
        if (res != null) {
          this.setState({ ipAceite: res.data.ip });
        } else {
          return;
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error al recuperar la ip del usuario",
        });
      });
  }

  //Fecha o modal dos termos
  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleAceitarTerminos = () => {
    document.getElementById("termos").checked = true;
    this.setState({ termos: true });
  };

  //Abre o modal dos termos
  handleShow = () => {
    this.setState({ showModal: true });
  };

  //Realiza a verificação dos dados e a gravação do usuário
  salvarUsuario = (e) => {
    e.preventDefault();
    const email = this.state.correioEletronico;
    const emailValid = validator.isEmail(email);
    let val = this.verificaCampos();
    if (!emailValid) {
      this.showAlertEmailNotValid();
      return;
    }
    if (this.state.termos === false) {
      this.showAlertAcceptTerm();
      return;
    }

    if (val) {
      this.showLoadingRegister();
      let validation;
      var dataAtual = new Date();
      const user = {
        empresa: this.state.organizacao,
        representante: this.state.representante,
        telefone: this.state.telefone,
        email: this.state.correioEletronico,
        descricao: this.state.descricao,

        locEstacao: this.state.dadosInteresse1
          ? this.state.dadosInteresse1
          : false,
        horarioLinha: this.state.dadosInteresse2
          ? this.state.dadosInteresse2
          : false,
        chegadaOnibusTr: this.state.dadosInteresse3
          ? this.state.dadosInteresse3
          : false,
        alertaUsuario: this.state.dadosInteresse4
          ? this.state.dadosInteresse4
          : false,
        locOnibus: this.state.dadosInteresse5
          ? this.state.dadosInteresse5
          : false,

        aceitaTermos: this.state.termos,
        acessoApi: this.state.acessoApi,
        dataHoraAceite: dataAtual,
        ipAceite: this.state.ipAceite,
      };

      GtfsService.gravarUsuario(user)
        .then((res) => {
          validation = res.data;
          if (
            validation !== "" &&
            validation === "User successfully registered"
          ) {
            history.push("/");
            Swal.close();
            this.showAlertUserSaved();
          } else if (
            validation !== "" &&
            validation === "User already registered"
          ) {
            Swal.close();
            this.showAlertUserRegistered();
          } else {
            Swal.close();
            this.showAlertUserNotSaved();
          }
        })
        .catch((err) => {
          Swal.close();
          this.showAlertUserNotSaved();
        });
    }
  };

  //Verifica se ao menos um dado de interesse foi selecionado
  verificaDadosInteresse() {
    let count = 0;
    count = this.state.dadosInteresse1 === true ? count + 1 : count;
    count = this.state.dadosInteresse2 === true ? count + 1 : count;
    count = this.state.dadosInteresse3 === true ? count + 1 : count;
    count = this.state.dadosInteresse4 === true ? count + 1 : count;
    count = this.state.dadosInteresse5 === true ? count + 1 : count;
    return count;
  }

  //Verifica se algum dos campos obrigatórios estão vazios
  verificaCampos = () => {
    let dadosInteresse = this.verificaDadosInteresse();
    if (dadosInteresse < 1) {
      this.showAlertNotFoundDadosInteresse();
      return;
    } else {
      return true;
    }
  };

  render() {
    const logo = require("../assets/img/metrobuslogo.jpg");
    return (
      <div className="bodyRegister">
        <div className="containerRegister">
          <header className="headerRegister">
            <div className="headerLogo">
              <img src={logo} alt="Logo Metrobús" />
              <div className="headerTeste">
                <h1>Registro de acceso</h1>
                <h1>Datos abiertos</h1>
              </div>
            </div>

            <p className="headerContent">
              Los datos marcados con un asterisco (*) son obligatorios y
              servirán para crear un registro de personas que tienen acceso,
              gestión y uso al sistema de información de datos API.
            </p>

            <footer>
              IMPORTANTE: es obligatorio renovar los datos de este cuestionario
              anualmente
            </footer>
          </header>

          <form onSubmit={this.salvarUsuario}>
            <div className="row">
              <p className="headerForm">
                <span>*</span>Obligatorio
              </p>

              <div className="containerForm">
                <label htmlFor="organizacao">
                  <span className="number">1.</span>NOMBRE DE ORGANIZACIÓN /
                  EMPRESA / PROYECTO<span className="campoObrigatorio">*</span>
                </label>
                <input
                  type="text"
                  id="organizacao"
                  name="organizacao"
                  placeholder="Ingresa tu respuesta"
                  required
                  value={this.state.organizacao}
                  maxLength="150"
                  onChange={this.changeOrgHandler}
                />
              </div>

              <div className="containerForm">
                <label htmlFor="representante">
                  <span className="number">2.</span>REPRESENTANTE (LEGAL, en
                  caso de ser persona Moral)
                  <span className="campoObrigatorio">*</span>
                </label>
                <input
                  type="text"
                  id="representante"
                  placeholder="Ingresa tu respuesta"
                  name="representante"
                  required
                  value={this.state.representante}
                  maxLength="50"
                  onChange={this.changeRepHandler}
                />
              </div>

              <div className="containerForm">
                <label htmlFor="telefone">
                  <span className="number">3.</span>TELÉFONO
                  <span className="campoObrigatorio">*</span>
                </label>
                <input
                  type="number"
                  id="telefone"
                  placeholder="Ingresa tu respuesta"
                  name="telefone"
                  required
                  value={this.state.telefone}
                  maxLength="50"
                  onChange={this.changeTelHandler}
                />
              </div>

              <div className="containerForm">
                <label htmlFor="email">
                  <span className="number">4.</span>CORREO ELECTRONICO
                  <span className="campoObrigatorio">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Ingresa tu respuesta"
                  required
                  value={this.state.correioEletronico}
                  maxLength="150"
                  onChange={this.changeCorEleHandler}
                />
              </div>

              <div className="containerForm">
                <label htmlFor="descricao">
                  <span className="number">5.</span>DESCRIPCIÓN (Breve
                  descripción del uso de la Información)
                  <span className="campoObrigatorio">*</span>
                </label>
                <input
                  className="camp5"
                  type="text"
                  id="descricao"
                  name="descricao"
                  placeholder="Ingresa tu respuesta"
                  required
                  value={this.state.descricao}
                  onChange={this.changeDescHandler}
                />
              </div>

              <div className="containerForm">
                <label htmlFor="camp6">
                  <span className="number">6.</span>Datos de interés
                  <span className="campoObrigatorio">*</span>
                </label>
                <div className="containerRadio">
                  <input
                    type="checkbox"
                    name="op1"
                    id="op1"
                    value={this.state.dadosInteresse1}
                    onChange={this.changeDesc1Handler}
                  />
                  <label htmlFor="op1">Ubicación de las estaciones.</label>
                </div>

                <div className="containerRadio">
                  <input
                    type="checkbox"
                    name="op2"
                    id="op2"
                    value={this.state.dadosInteresse2}
                    onChange={this.changeDesc2Handler}
                  />
                  <label htmlFor="op2">Horarios de cada Línea/Ruta.</label>
                </div>

                <div className="containerRadio">
                  <input
                    type="checkbox"
                    name="op3"
                    id="op3"
                    value={this.state.dadosInteresse3}
                    onChange={this.changeDesc3Handler}
                  />
                  <label htmlFor="op3">
                    Llegada de autobuses a cada estación en tiempo real.
                  </label>
                </div>

                <div className="containerRadio">
                  <input
                    type="checkbox"
                    name="op4"
                    id="op4"
                    value={this.state.dadosInteresse4}
                    onChange={this.changeDesc4Handler}
                  />
                  <label htmlFor="op4">
                    Alertas del sistema y avisos a usuarios.
                  </label>
                </div>

                <div className="containerRadio">
                  <input
                    type="checkbox"
                    name="op5"
                    id="op5"
                    value={this.state.dadosInteresse5}
                    onChange={this.changeDesc5Handler}
                  />
                  <label htmlFor="op5">Ubicación de autobuses.</label>
                </div>
              </div>

              <div className="containerForm">
                <label htmlFor="termos">
                  <span className="number">
                    7. ¿Acepta los términos y condiciones?
                  </span>
                  <span className="campoObrigatorio">*</span>
                </label>

                <p className="termos">
                  "Los datos personales recabados, serán protegidos,
                  incorporados y tratados en el Sistema de Datos Personales de
                  Sonda. Asimismo, se le informa que sus datos no podrán ser
                  difundidos sin su consentimiento expreso, salvo las
                  excepciones previstas en la Ley”
                  <br />
                </p>

                <>
                  <Button
                    variant="primary"
                    className="btn btn-secondary"
                    id="termosButton"
                    data-toggle="modal"
                    onClick={this.handleShow}
                  >
                    Términos y condiciones
                  </Button>
                  <Modal
                    className="modal"
                    show={this.state.showModal}
                    onHide={this.handleClose}
                    dialogClassName="custom-modal-termos"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title style={{ textAlign: "center" }}>
                        Términos y Condiciones para el Uso de Datos Abiertos del
                        Servicio de Transporte Público Metrobús de la Ciudad de
                        México
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        Estos términos y condiciones aplican para el uso del
                        Acceso para Datos Abiertos del Servicio de Transporte
                        Público Metrobús de la Ciudad de México. Metrobús puede
                        revisar éstos Términos y condiciones en cualquier
                        momento sin previo aviso. En caso de que haya algún
                        cambio, depende de usted revisar regularmente los
                        mismos, los cuales estarán disponibles en este sitio web{" "}
                        <a href="https://metrobus-gtfs.sinopticoplus.com/">
                          https://metrobus-gtfs.sinopticoplus.com/
                        </a>
                        . Para el uso continuo de los datos de transporte, si
                        recibe información después de que se realice un cambio
                        en estos Términos y Condiciones, se considerará como que
                        usted acepta dicho cambio.
                      </p>
                      <br />
                      <b>Registro</b>
                      <p>
                        Para poder hacer uso de los Datos Abiertos de Metrobús,
                        es necesario hacer un registro donde se proporcionan los
                        siguientes datos:
                      </p>
                      <ul>
                        <li>
                          Nombre de Organización/Empresa/Proyecto. De quien hará
                          uso de los Datos Abiertos.
                        </li>
                        <li>
                          Representante legal (En caso de ser persona moral).
                        </li>
                        <li>Teléfono.</li>
                        <li>
                          Correo electrónico. Donde podrá recibir usuario y
                          contraseña para accesar a los Datos Abiertos de
                          Metrobús.
                        </li>
                        <li>
                          Descripción. Tendrá que realizar una breve descripción
                          del uso de la Información de los Datos Abiertos de
                          Metrobús.
                        </li>
                        <li>
                          Datos de interés que desea que se proporcionen de la
                          siguiente lista:
                        </li>
                        <ul>
                          <li>Ubicación de las estaciones.</li>
                          <li>Horarios de cada Línea/Ruta.</li>
                          <li>
                            Llegada de autobuses a cada estación en tiempo real.
                          </li>
                          <li>Alertas del sistema y avisos a usuarios.</li>
                          <li>Ubicación de autobuses.</li>
                        </ul>
                      </ul>
                      <br />
                      <p>
                        Los datos personales recabados, serán protegidos,
                        incorporados y tratados en el Sistema de Datos
                        Personales de SONDA S.A. Asimismo, se le informa que sus
                        datos no podrán ser difundidos sin su consentimiento
                        expreso, salvo las excepciones previstas en la Ley
                        Federal de Protección de Datos Personales en posesión de
                        los particulares. El aviso de privacidad con respecto a
                        la información que se maneja se puede consultar en el
                        enlace:
                      </p>
                      <a
                        href="https://www.sonda.com/docs/default-source/politicas/aviso-de-privacidad-sonda-2022.pdf?sfvrsn=833b5de1_1"
                        target="_blanck"
                      >
                        https://www.sonda.com/docs/default-source/politicas/aviso-de-privacidad-sonda-2022.pdf?sfvrsn=833b5de1_1
                      </a>
                      <br />
                      <br />
                      <b>Uso de la información bajo este Acceso</b>
                      <p>
                        Metrobús le otorga un Acceso para ser usado en cualquier
                        parte del mundo, libre de regalías, renovable en
                        periodos de 6 meses y no exclusiva, para usar la
                        Información sujeta a las condiciones que se detallan a
                        continuación.
                      </p>
                      <p>
                        Este Acceso no afecta su libertad en cuanto a
                        condiciones de uso justo, trato justo, cualquier otro
                        copyright o limitación de derechos de autor o de base de
                        datos.
                      </p>
                      <p>
                        El Acceso se aplicará a partir de la fecha de registro y
                        continuará durante un período de 6 meses. Después de
                        éstos 6 meses, usted tendrá que crear un nuevo registro
                        y se le otorgará un nuevo Acceso.
                      </p>
                      <b>
                        Proceso para el Uso de los Datos Abiertos de Metrobús
                      </b>
                      <p>
                        Las personas registradas, podrán hacer uso de los datos
                        abiertos de Metrobús de la siguiente forma:
                      </p>
                      <ul className="numerada">
                        <li>
                          Llenar el formato de registro ubicado en el enlace{" "}
                          <a href="https://metrobus-gtfs.sinopticoplus.com/">
                            https://metrobus-gtfs.sinopticoplus.com/
                          </a>{" "}
                          para obtener acceso.
                        </li>
                        <li>
                          Vía correo electrónico se proporcionará usuario y
                          contraseña para acceder a los datos libres de
                          Metrobús.
                        </li>
                        <li>
                          Realizar las consultas mientras el registro esté
                          vigente.
                        </li>
                      </ul>
                      <b>Contenido de la Información de los Datos Abiertos.</b>
                      <p>
                        Mediante su registro, usted podrá obtener la siguiente
                        información de Datos Abiertos, ya sean estáticos o en
                        tiempo real:
                      </p>
                      <ul>
                        <li>Datos estáticos: </li>
                        <ul>
                          <li>Ubicación de las estaciones.</li>
                          <li>Horarios de cada Línea/Ruta.</li>
                        </ul>
                        <li>Datos en tiempo real: </li>
                        <ul>
                          <li>
                            Llegada de autobuses a cada estación, la cual se
                            actualiza cada 30 segundos.
                          </li>
                          <li>Alertas del sistema y avisos a usuarios.</li>
                          <li>Ubicación de autobuses.</li>
                        </ul>
                      </ul>
                      <b>Derechos</b>
                      <p>Usted es libre de: </p>
                      <ul>
                        <li>
                          Copiar, publicar y transmitir la información siempre y
                          cuando sea sin fines de lucro.
                        </li>
                        <li>Adaptar la información.</li>
                        <li>
                          Explotar la información, por ejemplo, combinándola con
                          otra información o incluyéndola en su propia
                          aplicación o servicio de información.
                        </li>
                      </ul>
                      <b>Requerimientos</b>
                      <p>Este Acceso: </p>
                      <ul>
                        <li>
                          No transfiere cualquier derecho de propiedad
                          intelectual en la Información a usted o a un tercero
                        </li>
                        <li>No incluye datos personales en la información. </li>
                        <li>
                          No proporciona cualquier derecho de uso de la
                          información después de la finalización de este Acceso.
                        </li>
                        <li>
                          No proporciona cualquier derecho de uso de cualquier
                          otro derecho de propiedad intelectual, incluidas las
                          patentes, marcas comerciales y derechos de diseño.
                        </li>
                        <li>
                          No proporciona derechos para la distribución de datos.
                        </li>
                      </ul>
                      <p>
                        Es importante cumplir con las condiciones de este Acceso
                        y, si no las cumple, los derechos otorgados a Usted bajo
                        este Acceso finalizarán automáticamente.
                      </p>
                      <b>No aprobación</b>
                      <p>
                        Esta Acceso no le otorga ningún derecho a utilizar la
                        Información de forma que Metrobús lo respalde a usted o
                        al uso que le dé a la Información.
                      </p>
                      <b>Sin garantía</b>
                      <p>
                        La Información se otorga bajo el Acceso y Metrobús
                        excluye todas las representaciones, garantías,
                        obligaciones y responsabilidades en relación con la
                        Información en la medida máxima permitida por la ley
                        Federal de Protección de Datos Personales en posesión de
                        los particulares.
                      </p>
                      <p>
                        Metrobús no es responsable por ningún error u omisión en
                        la Información y no será responsable por cualquier
                        pérdida, lesión o daño de cualquier tipo causado por su
                        uso o por la imposibilidad de usar el Servicio.
                      </p>
                      <p>
                        Metrobús no garantiza el suministro continuo de la
                        información.
                      </p>
                      <p>
                        Nada en este Acceso responsabilizará a Metrobús, por
                        agravios, lesiones, etc., causadas por negligencia en el
                        uso de la información.
                      </p>
                      <p>
                        Este Acceso se rige por las leyes de los Estados Unidos
                        Mexicanos, así como por la legislación vigente en la
                        Ciudad de México.
                      </p>
                      <br />
                      <a
                        href="https://www.sonda.com/docs/default-source/politicas/aviso-de-privacidad-sonda-2022.pdf?sfvrsn=833b5de1_1"
                        target="_blanck"
                      >
                        https://www.sonda.com/docs/default-source/politicas/aviso-de-privacidad-sonda-2022.pdf?sfvrsn=833b5de1_1
                      </a>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        id="termosButton"
                        data-toggle="modal"
                        onClick={() => {
                          this.handleClose();
                          this.handleAceitarTerminos();
                        }}
                      >
                        Aceptar los terminos
                      </Button>
                      <Button variant="secondary" onClick={this.handleClose}>
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
                <div className="containerRadio mt-20">
                  <input
                    className="ml-0"
                    type="checkbox"
                    name="termos"
                    id="termos"
                    value={this.state.termos}
                    onChange={this.changeTermosHandler}
                  />
                  <label htmlFor="termos">He leído y acepto</label>
                </div>
                <div className="containerRadio mt-20">
                  <input
                    className="ml-0"
                    type="checkbox"
                    name="acessoApi"
                    id="acessoApi"
                    value={this.state.acessoApi}
                    onChange={this.changeAcessoApiHandler}
                  />
                  <label htmlFor="acessoApi">
                    Solicito acceso para usar la API
                  </label>
                </div>
                <div>
                  <button type="submit">Mandar</button>&nbsp;&nbsp;&nbsp;
                  <Link to="/">
                    <button type="submit">Cancelar</button>
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default RegisterComponent;
