import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import useAuth from '../context/useAuth';
import GtfsService from '../services/GtfsService';
import validator from 'validator'
import history from '../history';

class LoginComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: ''
        }
        this.changeEmailHandler = this.changeEmailHandler.bind(this);
    }

    showAlertUserNotFound = () => {
        Swal.fire({
                    icon: 'error',
					title: 'Correo electrónico no registrado, ¿quieres registrarte?',
                    showCancelButton: true,
                    confirmButtonText: 'Sí',
						}).then((result) =>{
                            if (result.isConfirmed) {
                                history.push("/static/add-user");
                            }
                        })	
    }

    showAlertUserEmpty = () => {
        Swal.fire({
                    icon: 'warning',
					title: 'Debe ingresar un correo electrónico',
						})	
    }

    showAlertErrorLogin = () => {
        Swal.fire({
                    icon: 'error',
					title: 'Error al intentar iniciar sesión',
						})	
    }

    showAlertUserAuthenticated = () => {
        Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    width: 500,
                    timer: 3000,
                    timerProgressBar: true,
                    icon: 'success',
					title: 'Acceso permitido'
						})	
    }

    showAlertEmailNotValid = () => {
        Swal.fire({
                    icon: 'warning',
					title: 'Formato de correo inválido',
						})	
                        return;	
    }

    changeEmailHandler= (event) => {
        this.setState({email: event.target.value});
    }

    verificaEmail = (e) => {
        e.preventDefault();
        let email = this.state.email;
        const emailValid = validator.isEmail(email)
        if(!emailValid){
            this.showAlertEmailNotValid();
            return;
        }
        let validation;

        if(!email || email === ''){
            this.showAlertUserEmpty();
            return
        }

        GtfsService.verifyEmail(email).then((res) =>{
            validation = res.data;
            if(validation !== '' && validation !== 'not registered'){
                useAuth.handleLogin(res.data);
                history.push('/static/sendEmail');
                this.showAlertUserAuthenticated();
            }else{
                this.showAlertUserNotFound();
                return;
    
            }
        }).catch((err) =>{
            this.showAlertErrorLogin();
            return;
        });

    }
    
    

render() {
    const logo = require('../assets/img/logo-login.png');
	return (
        <div id="login">
            <div className="containerLogin">
            <header className="headerStyle">
                <img src={logo} alt="Logo Metrobús"/>
                <div>
                    <h1>Registro de acceso</h1>
                    <br/>
                    <h1>Datos abiertos</h1>
                </div>
            </header>
            

            <form>
                <div className="loginBox">
                <h2>Ya estoy registrado</h2>

                <div className="loginBox__container"> 
                    <label htmlFor="email">Correo electronico</label>
                    <input type="email" id="email" value={this.state.email} onChange={this.changeEmailHandler}/>
                </div>

                <button type="submit" name="email" onClick={this.verificaEmail}>INICIAR SESIÓN</button>
                </div>

                <div className="loginRegis">
                <h2>¿No tienes cuenta?</h2>
                <h3>Configure su cuenta gratuita para obtner acesso completo.</h3>
                <Link to="/static/add-user"><button type="submit">REGISTRARSE</button></Link>
                </div>
            </form>
            </div>
        </div>
	)
}
}

export default LoginComponent;