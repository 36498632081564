import React from 'react';
import './App.css';
import { Router, Route, Switch } from 'react-router-dom';
import LoginComponent from './components/LoginComponent';
import './assets/style.scss'
import RegisterComponent from './components/RegisterComponent';
import SendComponent from './components/SendComponent';
import history from './history';


function App() {
	return (
		<div>
				<Router history={history}>
						<Switch>
							<Route path="/" exact component={LoginComponent}/>
							<Route path="/static/sendEmail" component={SendComponent}/>
							<Route path="/static/add-user" component={RegisterComponent}/>
						</Switch>
				</Router>
		</div>


	);
}

export default App;