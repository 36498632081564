import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_GTFS_METROBUS, //Production
    //baseURL: "http://zn0-api3:8092", //Development
  });
  api.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  export default api;