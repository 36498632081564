import React, { Component } from 'react'
import { isExpired, decodeToken } from 'react-jwt';
import { Link } from 'react-router-dom';
import useAuth from '../context/useAuth';
import GtfsService from '../services/GtfsService';
import Swal from 'sweetalert2';
import history from '../history';

class SendComponent extends Component {

    showAlertEmailSender = () => {
        Swal.fire({
                    icon: 'success',
					title: 'Correo electrónico enviado',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000 
						})	
                        return;
    }

    showLoading = () => {
        Swal.fire({
            title: 'Espere por favor !',
            html: 'Enviando correo electrónico!',// add html attribute if you want or remove
            allowOutsideClick: false,
            allowEscapeKey: false,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        });
    }

    showAlertEmailError = () => {
        Swal.fire({
                    icon: 'error',
					title: 'Error al enviar correo electrónico',
                    text: 'Por favor, póngase en contacto con el administrador'
						})	
    }

    constructor(props) {
		super(props)

		this.state = {
			email:'',
            nomeCliente:'',
            idCliente:''
		}
	}

    componentDidMount(){
        this.senderEmail();
    }

    senderEmail = () => {
        this.showLoading();
        const token = localStorage.getItem('token');
        const myDecodedToken = decodeToken(token);
        const isMyTokenExpired = isExpired(token);

        if(isMyTokenExpired){
            useAuth.handleLogout();

        }else if(!isMyTokenExpired){
            this.setState({email: myDecodedToken.email});
            this.setState({nomeCliente: myDecodedToken.nomeCliente})
            this.setState({idCliente: myDecodedToken.idCliente})

            const email = myDecodedToken.email
            const idCliente = myDecodedToken.idCliente

            GtfsService.senderEmail(idCliente, email, token).then((res) =>{
                let validation = res.data;
                if(validation === 'E-mail has been sent'){
                    Swal.close();
                    this.showAlertEmailSender();
                    return;
                }else{
                    Swal.close();
                    this.showAlertEmailError();
                    return;
                }
            }).catch((err) =>{
                Swal.close();
                this.showAlertEmailError();
                return;
            });
        }
    }


    logout(){
        useAuth.handleLogout();
        history.push("/");
    }

    render() {
        const logo = require('../assets/img/logo-login.png');
        return (
            <div id="sender">
            <div className="containerLogin">
                    <header className="headerStyle">
                    <img src={logo} alt="Logo Metrobús"/>
                    <h1>Datos abiertos</h1>
                </header>
                <div className="containerSend"> 
                    <center><h3>GTFS {this.state.nomeCliente}</h3></center>
                    <br></br>
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre Organización</th>
                                <th>Su correo electrónico</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{this.state.nomeCliente}</td>
                                <td>{this.state.email}</td>
                            </tr>
                        </tbody>
                    </table>
                    <br></br>
                    <button type="button" className="btn btn-outline-success" onClick={this.senderEmail}>Reenviar</button>
                    <br /><br />
                    <p className="informacao">
                    Correo electrónico de datos abiertos enviado a la dirección registrada.
                    </p>
                    <p className="informacao">
                    Atención, el enlace del archivo GTFS se enviará a su correo electrónico de registro, 
                    este enlace es válido durante 12 horas desde el momento en que se envía!
                    </p>
                    <Link to="/"><button type="submit" onClick={this.logout}>Cerrar sesión</button></Link>
                </div>
            </div>
        </div>
        )
    }
}

    export default SendComponent